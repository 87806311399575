
<template>
    <div>
        <v-app-bar app class="elevation-0">
            <v-app-bar-nav-icon color="primary" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-btn icon="" to="/dashboard" color="primary">
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn icon="" @click="setTheme()" color="primary">
                <v-icon>mdi-theme-light-dark</v-icon>
            </v-btn> -->
            <v-btn icon="" @click="logout" color="primary">
                <v-icon>mdi-logout</v-icon>
            </v-btn>
            
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" app :right="$store.getters.language.rtl">
            <template v-slot:prepend>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-center">{{$store.getters.language.app_name}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <v-divider class="mt-3"></v-divider>
            <v-list shaped>
                <v-list-item v-for="(item , index) in navigation_links" :key="index" :to="item.path">
                <v-list-item-content>
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
import { auth } from '../firebase'
export default {
    data: function () {
        return {
            drawer: null,
        }
    },
    computed: {
        languages() {
            return this.$store.getters.languages
        },
        navigation_links() {
            return [
                
                    // {
                    //     path : '/admins-list',
                    //     title : 'Admins',
                    // },
            
                    {
                        path : '/customers-list',
                        title : 'Customers',
                    },
            
                    // {
                    //     path : '/customer_links-list',
                    //     title : 'Customer Links',
                    // },
            
                    {
                        path : '/links-list',
                        title : 'Links',
                    },
            
            ]
        },
    },
    methods: {
        logout() {
            auth.signOut()
        },
        setTheme() {
            const currentTheme = this.$vuetify.theme.dark
            if (currentTheme) {
                this.$vuetify.theme.dark = false
                localStorage.setItem('theme', 'light')
            } else {
                this.$vuetify.theme.dark = true
                localStorage.setItem('theme', 'dark')
            }
        }
    },
}
</script>
        