import {
        initializeApp
} from "firebase/app";
import "firebase/auth";
import {
        getAuth,
        signInWithEmailAndPassword,
        sendPasswordResetEmail
} from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
        apiKey: "AIzaSyAyOIqVLNKUW2CeEm1bcobbWQWfXEjbw-o",
        authDomain: "void-airport.firebaseapp.com",
        projectId: "void-airport",
        storageBucket: "void-airport.appspot.com",
        messagingSenderId: "368485735995",
        appId: "1:368485735995:web:a6e8ac7f7906f33805061e",
        measurementId: "G-SSRS1N0XVF"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export {
        auth,
        app,
        signInWithEmailAndPassword,
        sendPasswordResetEmail
}