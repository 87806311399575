
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import {
    auth
} from '../firebase'
const routes = [
    {
        path: '/dashboard',
        name: 'Home',
        component: () => import('./../views/Home.vue'),
        meta:{
            auth : true,
            title : 'Home'
        }
    },
    {
        path: '/',
        name: 'index',
        component: () => import('./../views/index.vue'),
        meta:{
            auth : false,
            title : 'index'
        }
    },
    {
        path: '/@/:id',
        name: 'View',
        component: () => import('./../views/View.vue'),
        meta:{
            auth : false,
            title : 'View'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./../views/Login.vue'),
        meta:{
            auth : false,
            title : 'Login'
        }
    },
    
        {
            path: '/admins-list',
            name: 'ListAdmins',
            component: () => import('./../views/Admins/List.vue'),
            meta:{
                auth : true,
                title : 'Admins List'
            }
        },
            {
            path: '/admins-list/:id',
            name: 'ViewAdmins',
            component: () => import('./../views/Admins/Edit.vue'),
            meta:{
                auth : true,
                title : 'Admins Edit'
            }
        },
        {
            path: '/customers-list',
            name: 'ListCustomers',
            component: () => import('./../views/Customers/List.vue'),
            meta:{
                auth : true,
                title : 'Customers List'
            }
        },
            {
            path: '/customers-list/:id',
            name: 'ViewCustomers',
            component: () => import('./../views/Customers/Edit.vue'),
            meta:{
                auth : true,
                title : 'Customers Edit'
            }
        },
            {
            path: '/customers/:id',
            name: 'ViewCustomers',
            component: () => import('./../views/Customers/Links.vue'),
            meta:{
                auth : true,
                title : 'Customers Links'
            }
        },
        {
            path: '/customer_links-list',
            name: 'ListCustomer_links',
            component: () => import('./../views/Customer_links/List.vue'),
            meta:{
                auth : true,
                title : 'Customer_links List'
            }
        },
            {
            path: '/customer_links-list/:id',
            name: 'ViewCustomer_links',
            component: () => import('./../views/Customer_links/Edit.vue'),
            meta:{
                auth : true,
                title : 'Customer_links Edit'
            }
        },
        {
            path: '/links-list',
            name: 'ListLinks',
            component: () => import('./../views/Links/List.vue'),
            meta:{
                auth : true,
                title : 'Links List'
            }
        },
            {
            path: '/links-list/:id',
            name: 'ViewLinks',
            component: () => import('./../views/Links/Edit.vue'),
            meta:{
                auth : true,
                title : 'Links Edit'
            }
        },
]
const router = new VueRouter({
    routes,
    mode:'history'
})

router.beforeEach((to, from, next) => {
    // Check if the route requires authentication and the user is not logged in
    auth.onAuthStateChanged(user => {
        if (to.matched.some(record => record.meta.auth) && !user) {
            next({
                path: '/login',
            })
        } else if(!to.matched.some(record => record.meta.auth) && !user){
            next()
        } else {
            next()
        }
    })
})

export default router
        