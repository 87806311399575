
<template>
    <v-app>
        <APPNavbar v-if="isAuth"></APPNavbar>
        <v-main>
        <router-view/>
        </v-main>
    </v-app>
</template>
      
<script>
import APPNavbar from './components/Navbar.vue'
import {auth} from './firebase'
export default {
    components:{
        APPNavbar
    },
    name: 'App',

    data: () => ({
        loading: true,
        isAuth: false,
    }),
    created() {
        auth.onAuthStateChanged((user) => {
            this.loading = false
            if (user) {
                this.isAuth = true
            } else {
                this.isAuth = false
            }
        })
        
        // check theme
        const theme = localStorage.getItem('theme')
        if (theme === 'dark') {
            this.$vuetify.theme.dark = true
        } else {
            this.$vuetify.theme.dark = false
        }
    },
};
</script>
<style>
    @import url('./assets/main.css');
    * {
        font-family: 'Vazirmatn', sans-serif;
    }
</style>