
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import languages from './languages.json'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        languages: languages,
        language: languages[0],
        
        admins_list: [],
        
        customers_list: [],
        
        customer_links_list: [],
        
        links_list: [],
        
    },
    getters: {
        language: state => state.language,
        languages: state => state.languages,
        
        admins_list: state => state.admins_list,
        
        customers_list: state => state.customers_list,
        
        customer_links_list: state => state.customer_links_list,
        
        links_list: state => state.links_list,
        
    },
    mutations: {
        setLanguage(state, language) {
            state.language = language
        },
        
        setAdminsList(state, admins_list) {
            state.admins_list = admins_list
        },
        
        setCustomersList(state, customers_list) {
            state.customers_list = customers_list
        },
        
        setCustomerLinksList(state, customer_links_list) {
            state.customer_links_list = customer_links_list
        },
        
        setLinksList(state, links_list) {
            state.links_list = links_list
        },
        
    },
    actions: {
        
    },
})
        